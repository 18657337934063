import { isMobile } from "../../DeviceType";
import Link from "next/link";
import ThemeContext from "../../../context/ThemeContext";
import { useContext } from "react";
import { useGlobalContext } from "../../../context/GlobalContext";
declare global {
  interface Window {
    drift?: any;
    LiveChatWidget?:any;
    $_Tawk?:any;
  }
}

const TalkToExpertDialog = (props: any) => {
  const { getTheme, isClientOrg } = useContext(ThemeContext);
  const {isDrift,showDrift,hideDrift,removeDriftClass}=useGlobalContext()
  const theme = getTheme();
  const { talkToExpert } = theme;
  const defaultAppointmentURL = isClientOrg ? '' : 'https://go.appointmentcore.com/book/sjBrdD';
  const { number: contactNumber, email, appointmentUrl }: any = talkToExpert;
  const appointmentLink = appointmentUrl || defaultAppointmentURL;
  // const contactNumber = contactNumbers[1] || contactNumbers[0]
  const { closeExpertModal,isAffiliateManage=false } = props;
  const onClickLiveChat = () => {
    closeExpertModal()
    removeDriftClass()
    showDrift()
  };
  const closeModal = ()=>{
    closeExpertModal()
    hideDrift()
  }
  return (
    <div className={`${isMobile ? "mobileView" : "onlyFaq"}`}>
      <div className="sticky top-0 bg-white z-10">
        <h2 className="px-6 pr-4 sm:px-6 py-4 pb-3 sm:py-4  text-2xl font-primarySemiBold text-titleColor mr-12 sm:mr-8">
          <p className="text-xl font-primarySemiBold text-titleColor  capitalize">
            Choose An Option
          </p>
        </h2>
        {/* CLOSE BUTTON */}
        <span className="cursor-pointer sm:w-auto absolute top-6 right-4 z-20">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="-mt-2 h-7 w-7 text-gray-300"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            onClick={closeModal}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </span>
      </div>
      <div className="lead-form-dialog__body md:pb-6">
        <div className="flex flex-col items-start gap-2.5 m-auto pt-2 pb-4 px-6 sm:pb-4">
          {contactNumber && <Link href={`tel:${contactNumber}`}>
            <div
              onClick={closeExpertModal}
              className="flex flex-row items-center gap-2 p-2 hover:bg-gray-50 cursor-pointer w-full"
            >
              <div className="bg-secondary p-2.5 rounded text-white">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                  />
                </svg>
              </div>
              <div className="flex flex-col gap-0.5 items-start">
                <span className="font-primaryMedium text-xl text-bodyColor">
                  Call
                </span>
                <span className=" text-xs font-inter font-primary text-bodyColor text-titlecolor">
                  Number:  {contactNumber}
                </span>
              </div>
              
            </div>
          </Link>
          }
          {/* <Link href="tel:385-367-5974"> */}
          {isDrift() && (
            <button
              onClick={() => {
                onClickLiveChat();
              }}
              className=" p-2 hover:bg-gray-50 cursor-pointer w-full"
            >
              <span
                className="flex flex-row items-center gap-2"
                onClick={closeExpertModal}
              >
                <div className="drift-widget-controller-icon square p-2.5 rounded">
                  <svg
                    aria-hidden="true"
                    className="drift-default-icon drift-default-icon--chat-round w-5"
                    viewBox="0 0 25 23"
                  >
                    <path
                      d="M24.516 9.953C24.516 4.453 19.04 0 12.258 0 5.476 0 0 4.452 0 9.953c0 3.318 1.986 6.24 5.05 8.053-.34 2.552-1.815 4.055-1.844 4.084-.14.14-.17.368-.113.567a.524.524 0 0 0 .482.312c2.95 0 5.335-1.93 6.612-3.206.652.086 1.362.142 2.07.142 6.783 0 12.26-4.452 12.26-9.953z"
                      fill="white"
                    ></path>
                  </svg>
                </div>
                <div className="flex flex-col gap-0.5 items-start">
                <span className="font-primaryMedium text-xl text-bodyColor">
                  Chat
                </span>
                {/* {email ? (
                  <span className=" text-xs font-inter font-primary text-bodyColor text-titlecolor">
                   Email: {email}
                  </span>
                ) : (
                  ""
                )} */}
                </div>
                
              </span>
            </button>
          )}

          {email && (
            <Link href={`mailto:${email}`}>
              <span
                className="flex flex-row items-center gap-2 p-2 cursor-pointer"
                onClick={closeExpertModal}
              >
                <div className="drift-widget-controller-icon square p-2.5 rounded">
                <svg
                    width="39"
                    viewBox="0 0 39 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="currentColor"
                    // className="w-5"
                    className="drift-default-icon drift-default-icon--chat-round w-5"
                  >
                    <path
                      d="M2 2.21094H37.3658V26.5249C37.3658 27.7457 36.3762 28.7353 35.1554 28.7353H4.21037C2.98962 28.7353 2 27.7457 2 26.5249V2.21094Z"
                      stroke="white"
                      stroke-width="3"
                    />
                    <path
                      d="M2 2.21094L19.6829 15.4731L37.3658 2.21094"
                      stroke="white"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex flex-col gap-0.5 items-start">
                <span className="font-primaryMedium text-xl text-bodyColor">
                  Mail
                </span>
                <span className=" text-xs font-inter font-primary text-bodyColor text-titlecolor break-all">
                  Email: {email}
                </span>
                </div>
                
              </span>
            </Link>
          )}

          {/* </Link> */}
          {!isAffiliateManage && !!appointmentLink && (
            <a
              className="w-full"
              target={"_blank"}
              href={appointmentLink}
            >
              <div
                onClick={closeExpertModal}
                className="flex flex-row items-center gap-2 p-2 hover:bg-gray-50 cursor-pointer w-full"
              >
                <div className="bg-green-500 p-2.5 rounded text-white">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z"
                    />
                  </svg>
                </div>
                <span className="font-primaryMedium text-xl text-bodyColor">
                  Book an Appointment
                </span>
              </div>
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
export default TalkToExpertDialog;
