
import request from "../utils/callAPI";
import { Page } from "../utils/contentFetcher";
const baseUrl = process.env.API_URL
export default class CommonService {
    getThemeConfigURL='get-all-orgs-default-theme-data';
    getTabsURL="";

   
    getThemeConfig(){
        // return defaultTheme;
        const config = {
            method: "GET",
            url: this.getThemeConfigURL,
            baseUrl
           
        };
        return request(config);
    };

    getThemeByDomain ( domain:string ) {
        const config = {
            method: "GET",
            url: "get-default-theme-data",
            baseUrl,
            params: {domain}

        };
        return request(config);
    };

    getPageContent ( page: Page, domain:string) {
        const config = {
            method: "GET",
            url: "get-page-content",
            baseUrl,
            params: { domain, page }

        };
        return request( config );
    };


    async getTabs(orgID: number, hostname: number){
        const config = {
            method: "GET",
            url: `get-tabs-data/${orgID}`,
            params: {hostname}
        };
        const response = await request(config);
        return response.data.data;
    };
  
    async getUtiltyProvider(queryParams:{[key:string]:string}) {
    const config = {
      method: "GET",
      url: `utility-providers`,
      params: queryParams
    };
    return request(config);

}
async getEnerrgyAdvisorList(queryParams:{[key:string]:string}){
    const config = {
        method: "GET",
        url: `energy-advisors-list`,
        params: queryParams
      };
      return request(config);
}

}


// Orgs Theme Data - /get-all-orgs-default-theme-data
// Single Org Theme Data - /get-default-theme-data/:org_id
// Single Org Tabs Data - /get-tabs-data/:org_id
// Single Org Next Step Data - /get-next-steps-data/:org_id